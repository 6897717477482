import React from 'react';

import { Icon } from '@components';
import { getDataValueFromElement } from '@utils';

import { SimpleSite } from '../types';

import AccountMenuList from './AccountMenuList';
import BenchmarkMenu from './BenchmarkingMenu';
import ClientMenuList from './ClientMenuList';
import Link from './Link';

interface SidebarProps {
  clientList: any[];
  pageAccountSlug: string;
  canEditReports: boolean;
  open: boolean;
  selectedClientSlug: string;
  selectedAccountSlug: string;
  setSelectedAccountSlug: (string) => void;
  accountsList: SimpleSite[];
  managementAccessEnabled: boolean;
  isSuperuser: boolean;
  handleChange: (string) => void;
}

const Sidebar = ({
  clientList,
  pageAccountSlug,
  canEditReports,
  open,
  selectedClientSlug,
  selectedAccountSlug,
  setSelectedAccountSlug,
  accountsList,
  managementAccessEnabled,
  isSuperuser,
  handleChange,
}: SidebarProps): JSX.Element => {
  const currentActiveNav = getDataValueFromElement(
    'body-root',
    'data-active-nav',
  );

  return (
    <aside
      aria-label="sidebar-navigation"
      role="navigation"
      id="sidebar"
      className={`border-sp-neutral-200 text-sp-neutral absolute left-0 h-screen w-64 transform overflow-y-auto border-r bg-white font-medium transition duration-200 ease-in-out md:relative md:z-20 md:translate-x-0 ${
        open ? `z-20 h-screen translate-x-0` : '-translate-x-full'
      }  `}
    >
      <nav className="flex h-full flex-col pt-16 ">
        <div className="flex-auto">
          {location.pathname.startsWith('/management/benchmarking/') ? (
            <BenchmarkMenu />
          ) : (
            <form autoComplete="off">
              <ClientMenuList
                handleChange={handleChange}
                canEditReports={canEditReports}
                clientList={clientList}
                selectedClientSlug={selectedClientSlug}
                currentActiveNav={currentActiveNav ?? ''}
              />
              {selectedClientSlug ? (
                <>
                  <hr className="sp-divider mt-6" />
                  <AccountMenuList
                    pageAccountSlug={pageAccountSlug}
                    selectedAccountSlug={selectedAccountSlug}
                    accountsList={accountsList}
                    setSelectedAccountSlug={setSelectedAccountSlug}
                    currentActiveNav={currentActiveNav ?? ''}
                  />
                </>
              ) : null}
            </form>
          )}
        </div>

        <ul className="border-sp-neutral shrink-0">
          {managementAccessEnabled ? (
            <>
              <Link
                icon={<Icon color="white" name="FiLifeBuoy" />}
                href={'/management/'}
                disabled={false}
                label={'Management'}
                isActive={
                  currentActiveNav === ''
                    ? null
                    : currentActiveNav === 'management'
                }
              />
            </>
          ) : null}
          {isSuperuser ? (
            <Link
              icon={<Icon color="white" name="FiSettings" />}
              href={'/admin/'}
              disabled={false}
              label={'Admin'}
              isActive={
                currentActiveNav === '' ? null : currentActiveNav === 'admin'
              }
            />
          ) : null}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
