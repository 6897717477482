import React from 'react';

import BaseCard from './BaseCard';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const LightCard = ({ children, className, ...rest }: CardProps) => {
  return (
    <BaseCard light stacked className={className} {...rest}>
      {children}
    </BaseCard>
  );
};

export const DarkCard = ({ children, className, ...rest }: CardProps) => {
  return (
    <BaseCard stacked className={className} {...rest}>
      {children}
    </BaseCard>
  );
};
