import React, { useEffect, useState } from 'react';

import ReactDOM from 'react-dom';

import { OutlineButton, SolidButton } from '@components/Button';
import { getDataValueFromElement, jsonParseSafe } from '@utils';

const LOCALSTORAGE_KEY = `banner-announcements`;

interface Announcement {
  text: React.ReactNode;

  mainButton?: { label: React.ReactNode; onClick: () => void };

  /* if enabled, will show a button allowing the user to dismiss banner forever */
  allowDontShowAgain?: boolean;

  /* number of times before this announcement is never shown again */
  maxViewCount?: number;

  /* should not show this announcement after specific date */
  hideAfter?: Date;

  /* if specified, will allow hiding/showing the announcement based on a specific condition */
  enabled?: boolean;
}

export const Announcements = () => {
  const [bannerAnnouncementsState, setBannerAnnouncementsState] = useState(
    jsonParseSafe<
      Record<
        string,
        | { seenCount: number; acknowledged: boolean; remindAgainAt?: number }
        | undefined
      >
    >(localStorage.getItem(LOCALSTORAGE_KEY)) ?? {},
  );

  // save data to localstorage
  useEffect(() => {
    localStorage.setItem(
      LOCALSTORAGE_KEY,
      JSON.stringify(bannerAnnouncementsState),
    );
  }, [bannerAnnouncementsState]);

  const currentGscStatus = getDataValueFromElement(
    'body-root',
    'data-gsc-status',
  );

  const documentationUrl = '/docs/searchpilot/google-search-console-insights';

  const announcements: Record<string, Announcement> = {
    'gsc-feature-activation': {
      text: (
        <>
          Activate Google Search Console integration to enable additional
          insights for your tests. Activation of this Beta feature lets anyone
          on your team analyze tests for ranking and click-through rate changes.{' '}
          <a target="_blank" href={`${documentationUrl}`} className="underline">
            See our documentation
          </a>{' '}
          on how to activate this feature and reach out to your SearchPilot
          support contact to coordinate setup.
        </>
      ),
      enabled: currentGscStatus === 'pending',
    },
  };

  useEffect(() => {
    setBannerAnnouncementsState((prev) => ({
      ...prev,
      ...Object.fromEntries(
        Object.entries(announcements).map(([key, announcement]) => [
          key,
          {
            ...bannerAnnouncementsState[key],
            seenCount: (bannerAnnouncementsState[key]?.seenCount ?? 0) + 1,
            acknowledged: bannerAnnouncementsState[key]?.acknowledged ?? false,
          },
        ]),
      ),
    }));
  }, []);

  return ReactDOM.createPortal(
    <div className="shadow-top fixed bottom-0 left-0 z-50 w-full empty:hidden">
      {Object.entries(announcements)
        .filter(([, announcement]) => announcement.enabled !== false)
        .map(([key, announcement]) => {
          const hasPassedHideAfterDate =
            (announcement.hideAfter ?? new Date()) < new Date();

          const wasAcknowledged = bannerAnnouncementsState[key]?.acknowledged;

          const tooSoonToShowAgain =
            bannerAnnouncementsState[key]?.remindAgainAt ??
            0 > Number(new Date());

          const seenTooManyTimes =
            bannerAnnouncementsState[key]?.seenCount ??
            0 > (announcement.maxViewCount ?? 0);

          if (seenTooManyTimes) {
            return null;
          }

          if (hasPassedHideAfterDate) {
            return null;
          }

          if (wasAcknowledged) {
            return null;
          }

          if (tooSoonToShowAgain) {
            return null;
          }

          return (
            <div
              key={key}
              className="from-sp-pink-400 to-sp-primary-500 text-sp-primary-100 flex flex-wrap items-center justify-between gap-4 border-b border-pink-600 bg-gradient-to-r p-4 leading-5"
            >
              <div className="min-w-64 flex-1">{announcement.text}</div>

              <div className="flex flex-wrap justify-end gap-2">
                {announcement.allowDontShowAgain && (
                  <SolidButton
                    onClick={() => {
                      const ONE_HUNDRED_YEARS = 1000 * 60 * 60 * 24 * 365 * 100;

                      setBannerAnnouncementsState((prev) => ({
                        ...prev,
                        [key]: {
                          ...prev[key],
                          remindAgainAt: Number(new Date()) + ONE_HUNDRED_YEARS,
                          seenCount: prev[key]?.seenCount ?? 0,
                          acknowledged: prev[key]?.acknowledged ?? false,
                        },
                      }));
                    }}
                    variant="primary"
                  >
                    Don't show again
                  </SolidButton>
                )}

                <OutlineButton
                  onClick={() => {
                    const ONE_DAY = 1000 * 60 * 60 * 24;

                    setBannerAnnouncementsState((prev) => ({
                      ...prev,
                      [key]: {
                        ...prev[key],
                        remindAgainAt: Number(new Date()) + ONE_DAY,
                        seenCount: prev[key]?.seenCount ?? 0,
                        acknowledged: prev[key]?.acknowledged ?? false,
                      },
                    }));
                  }}
                  variant="primary"
                >
                  Remind me later
                </OutlineButton>

                {announcement.mainButton ? (
                  <OutlineButton
                    onClick={() => {
                      setBannerAnnouncementsState((prev) => ({
                        ...prev,
                        [key]: {
                          ...prev[key],
                          acknowledged: true,
                          seenCount: prev[key]?.seenCount ?? 0,
                        },
                      }));
                      announcement?.mainButton?.onClick();
                    }}
                    variant="primary"
                  >
                    {announcement.mainButton.label}
                  </OutlineButton>
                ) : null}
              </div>
            </div>
          );
        })}
    </div>,
    document.body,
  );
};
