export const makeModal = (
  title: string,
  body: string,
  okButtonText: string,
  cancelButtonText: string,
  onOK,
) => {
  const modal = document.createElement('div');
  modal.className =
    'fixed w-full h-full top-0 left-0 z-50 overflow-y-auto text-left';
  modal.id = 'modal-confirm';
  modal.innerHTML = `
      <div class="fixed w-full h-full bg-gray-900 opacity-50"></div>
      <div class="bg-white w-11/12 md:max-w-md mx-auto rounded-lg shadow-lg my-8 relative w-auto">
        <div class="sp-head-foot-block-container">
          <div class="sp-head-foot-block-header">
            <h2 class="text-3xl">${title}</h2>
          </div>
          <div class="sp-head-foot-block-content p-4">
            ${body}
          </div>
          <div class="sp-head-foot-block-footer text-right">
            <button
              type="button"
              class="bg-sp-white text-sp-neutral text-sm leading-5 font-medium rounded-md py-1 px-4 ml-3 h-8 rounded false"
              id="modal-confirm-close"
            >
              ${cancelButtonText}
            </button>
            <button
              type="button"
              class="bg-sp-primary text-white text-sm leading-5 font-medium rounded-md py-1 px-4 ml-3 h-8 rounded false"
              id="modal-confirm-confirm"
            >
              ${okButtonText}
            </button>
          </div>
        </div>
      </div>
    `;
  document.body.insertAdjacentElement('afterbegin', modal);

  const closeModalButton = document.getElementById('modal-confirm-close');
  closeModalButton?.addEventListener(
    'click',
    () => modal?.parentNode?.removeChild(modal),
    false,
  );

  const getInputs = () => modal.querySelectorAll('input');
  const getValues = () => {
    const inputs = getInputs();
    const values = {};
    inputs.forEach((input) => {
      values[input.name] = input.value;
    });
    return values;
  };

  const confirmModalButton = document.getElementById('modal-confirm-confirm');
  confirmModalButton?.addEventListener(
    'click',
    () => {
      const values = getValues();
      confirmModalButton.innerHTML =
        '<div class="animate-spin inline-block rounded-full border-2 border-t-2 border-sp-yellow border-top-sp-purple h-4 w-4 -mb-1 mr-0.5"></div>';
      confirmModalButton.classList.remove('bg-sp-secondary');
      confirmModalButton.classList.add('bg-white');
      if (onOK !== null) {
        onOK(values).finally(() => {
          modal?.parentNode?.removeChild(modal);
        });
      } else {
        modal?.parentNode?.removeChild(modal);
      }
    },
    false,
  );

  document.onkeydown = (event) => {
    let isEscape = false;
    if ('key' in event) {
      isEscape = event.key === 'Escape' || event.key === 'Esc';
    }
    if (isEscape) {
      modal.parentNode?.removeChild(modal);
    }
  };
};
