import React from 'react';

export const DescriptionList = ({ children }) => {
  return (
    <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
      {children}
    </dl>
  );
};

export const DescriptionItem = ({ term, details }) => {
  return (
    <div className="py-2 flex justify-between text-sm font-medium">
      <dt className="text-gray-500 pl-2">{term}</dt>
      <dd className="text-gray-900 pr-2">{details}</dd>
    </div>
  );
};
