import React from 'react';

const HelpText = ({ children }) => {
  const textStyle = 'm-0 text-sm text-sp-neutral-500 font-normal';
  if (children) {
    return <p className={textStyle}>{children}</p>;
  } else {
    return null;
  }
};

export default HelpText;
