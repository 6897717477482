import React from 'react';

import cn from 'classnames';

import TabsBase, { TabsBaseProps } from './TabsBase';

interface HorizontalTabsProps extends Omit<TabsBaseProps, 'vertical'> {
  type: 'button' | 'underline' | 'buttonBorder';
  fullWidth: boolean;
}

const mapHorizontalTabs = {
  button: `px-3 py-2 rounded-md mr-2`,
  underline: `pb-4 px-1 mr-4`,
  buttonBorder: `px-3 py-2 rounded-md mr-2`,
};

const HorizontalTabs = ({
  type,
  children,
  fullWidth,
  ...restProps
}: HorizontalTabsProps) => {
  return (
    <TabsBase
      {...restProps}
      vertical={false}
      type={type}
      className={cn({ 'w-60': fullWidth }, mapHorizontalTabs[type])}
    >
      {children}
    </TabsBase>
  );
};

HorizontalTabs.defaultProps = {
  type: 'underline',
  fullWidth: 'false',
};

export default HorizontalTabs;
