import React, { Children, isValidElement } from 'react';

import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import Icon, { IconNamesType } from '../Icon';
import { Tooltip } from '../Tooltip';

const mapColour = {
  green: 'bg-sp-success-400',
  yellow: 'bg-sp-warning-400',
  gray: 'bg-sp-neutral-400',
  blue: 'bg-sp-secondary-400',
  purple: 'bg-sp-primary-400',
};

interface MetricsCardProps {
  loading: boolean;
  type: 'chart' | 'simple';
  title: React.ReactNode;
  value: string;
  pill?: React.ReactNode;
  iconName?: IconNamesType;
  colour?: 'green' | 'yellow' | 'gray' | 'blue' | 'purple';
  children?: React.ReactNode;
  percentChange?: string;
  percentDescription?: string;
  infoTooltip?: string;
}

const MetricsCard = ({
  loading, //For skeleton loading
  type, //Instead of using type as props, this may be better off a separate component i.e <MetricCards.Simple/> <MetricCards.Change/>
  title,
  value,
  pill,
  iconName,
  colour,
  percentChange,
  percentDescription,
  children,
  infoTooltip,
}: MetricsCardProps) => {
  const validChildren = Children.toArray(children).filter((child) =>
    isValidElement(child),
  ) as any[];

  const chart = validChildren.find((child) => {
    return child.type.displayName === 'MetricsCardChart';
  });

  const infoTooltipIcon =
    infoTooltip &&
    (loading ? (
      <Skeleton width={20} />
    ) : (
      <Tooltip message={infoTooltip} tooltipPosition={'bottom'}>
        <Icon hoverColor={undefined} name={'infoCircleLetterI'} />
      </Tooltip>
    ));

  return (
    <div className="max-w-auto bg-sp-white flex flex-col gap-y-5 rounded-lg bg-white p-5 shadow-sm md:w-96 md:gap-y-6 md:p-6">
      {iconName && (
        <div className="flex justify-between">
          {loading ? (
            <>
              <Skeleton width={100} /> <Skeleton width={100} />
            </>
          ) : (
            <>
              <span className="flex flex-row items-center">
                <div
                  className={cn(
                    'rounded-1.5lg flex h-12 w-12 items-center justify-center',
                    colour ? mapColour[colour] : '',
                  )}
                >
                  <Icon
                    hoverColor={undefined}
                    name={iconName}
                    color={'text-white'}
                  />
                </div>
                {(chart && !pill) || type === 'chart' ? (
                  <p className="text-md text-sp-neutral-900 ml-4 font-medium">
                    {title}
                  </p>
                ) : null}
              </span>
              {infoTooltipIcon}
            </>
          )}
        </div>
      )}

      {type === 'simple' && (
        <div className="gap-y-2">
          <div className="mb-2 flex justify-between">
            {loading ? (
              <Skeleton width={100} />
            ) : (
              <p className="text-sp-neutral-500 text-sm">{title}</p>
            )}
            {!iconName && infoTooltipIcon}
          </div>
          <div className="flex items-center justify-between">
            {loading ? (
              <Skeleton width={100} />
            ) : (
              <p className="text-md text-4xl font-semibold">{value}</p>
            )}
            {pill && (loading ? <Skeleton width={50} /> : <div>{pill}</div>)}
          </div>
        </div>
      )}

      {type === 'chart' && (
        <div className="flex items-center justify-between">
          <div className="flex flex-col items-start justify-start gap-y-2">
            {loading ? (
              <Skeleton width={100} />
            ) : (
              <p className="text-md text-4xl font-semibold">{value}</p>
            )}
            <p className="text-sp-neutral-500 inline-flex items-center">
              {loading ? (
                <Skeleton width={200} />
              ) : (
                <>
                  <span className="text-sp-success mr-2 inline-flex items-center text-sm font-medium">
                    <Icon
                      hoverColor={undefined}
                      name={'FiArrowUp'}
                      color={'text-sp-success'}
                    />
                    {percentChange}
                  </span>
                  {percentDescription}{' '}
                </>
              )}
            </p>
          </div>
          {chart &&
            (loading ? (
              <Skeleton width={50} height={50} />
            ) : (
              <div>{chart}</div>
            ))}
        </div>
      )}
    </div>
  );
};

const MetricsCardGroup = ({ children }) => {
  return (
    <div className="my-8 flex w-auto flex-col gap-y-5 md:flex-row md:gap-x-6">
      {children}
    </div>
  );
};

//this is a placeholder so we can pass a chart within this component. TO DO: once we have a reusable chart component, i.e LineChart, we can probably make it more reusable so just pass 'chart type' and 'data' as props
const MetricsCardChart = ({ children }) => {
  return <div>{children}</div>;
};

MetricsCard.Chart = MetricsCardChart;
MetricsCard.Group = MetricsCardGroup;

MetricsCard.defaultProps = {
  loading: false,
  type: 'simple',
};

export default MetricsCard;
