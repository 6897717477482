import React from 'react';

const Divider = () => {
  return <hr className="sp-divider" />;
};

export const DividerWithContent = ({ children }) => {
  return (
    <div className="flex flex-row items-center">
      <hr className="sp-divider w-1/2 mr-2" />
      {children}
      <hr className="sp-divider w-1/2 ml-2" />
    </div>
  );
};

Divider.Simple = Divider;
Divider.Content = DividerWithContent;

export default Divider;
