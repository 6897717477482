import React from 'react';

import cn from 'classnames';

import { InputField } from '../InputField';
import { InputFieldProps } from '../InputField/types';

const CardHeader = ({
  children,
  roundedTop,
  roundedBottom,
  ...restProps
}: {
  children: React.ReactNode;
  roundedTop?: boolean;
  roundedBottom?: boolean;
}) => {
  return (
    <div
      className={cn('sp-card-header-wrapper', {
        'rounded-t-lg': roundedTop === true,
        'rounded-b-lg': roundedBottom === true,
      })}
      {...restProps}
    >
      {children}
    </div>
  );
};

const CardHeaderContent = ({ children, ...restProps }) => {
  return <div className="mr-4 grow justify-center">{children}</div>;
};

export const CardHeaderTitle = ({ children, ...restProps }) => {
  return (
    <h2 className="text-lg font-medium" {...restProps}>
      {children}
    </h2>
  );
};
export const CardHeaderDesription = ({ children, ...restProps }) => {
  return (
    <p className="text-sm text-gray-500" {...restProps}>
      {children}
    </p>
  );
};

export const CardButton = ({ children, as: Elem, ...restProps }) => {
  return <Elem {...restProps}>{children}</Elem>;
};

export const CardSearch = ({ placeholder, ...restProps }: InputFieldProps) => {
  return (
    <div className="grow">
      <InputField placeholder={placeholder} {...restProps} />
    </div>
  );
};

CardHeader.Content = CardHeaderContent;
CardHeader.Title = CardHeaderTitle;
CardHeader.Description = CardHeaderDesription;

CardHeader.Button = CardButton;
CardHeader.Search = CardSearch;

CardHeader.defaultProps = {
  roundedTop: true,
  roundedBottom: false,
};

export default CardHeader;
