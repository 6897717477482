
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

import { getDataValueFromElement } from '@utils';
const currentEnvironment = getDataValueFromElement(
  'meta_data',
  'data-current-environment',
);

if (currentEnvironment === 'prod' || currentEnvironment === 'staging') {
  Sentry.init({
    dsn: 'https://e271944935ce4a0f8bcb792b61dc8e3d@o154234.ingest.sentry.io/1429241',
    environment: currentEnvironment,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: 'distillery-frontend',
  });
}

import './authy';

// UTTER FILTH ALERT
// The following are imported so that they are copied when webpack runs
import '../images/favicon.ico';
import '../images/favicon_192.png';
import '../images/searchpilot.png';
import '../images/searchpilot-mark.png';
import '../images/searchpilot-chart-background.png';
// END UTTER FILTH ALERT
