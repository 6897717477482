import { getCookie } from './utils';

// replaceCSRFTokens will replace all the inputs with a
// csrf token with the most up to date version from cookies
export const replaceCSRFTokens = () => {
  const csrftoken = getCookie('csrftoken');
  if (!csrftoken) {
    return;
  }
  const inputs = document.querySelectorAll('input[name=csrfmiddlewaretoken]') as NodeListOf<HTMLInputElement>;
  inputs.forEach((el) => {
    el.value = csrftoken;
  });
};

// refresh all the form based csrf tokens every 5 seconds
setInterval(replaceCSRFTokens, 5000);
