import React, { useState, useContext, createContext } from 'react';

import cn from 'classnames';

import Icon, { IconNamesType } from '../Icon';

interface AlertPropTypes {
  variant: 'neutral' | 'warning' | 'info' | 'success' | 'error';
  message: string;
  children: React.ReactElement | React.ReactElement[] | React.ReactNode | null;
  dismissable: boolean;
}

const iconTypeMapping: Record<string, IconNamesType> = {
  neutral: 'infoCircleLetterI',
  info: 'infoCircleLetterI',
  success: 'successTick',
  warning: 'exclamationTriangle',
  error: 'alertCircle',
};

const iconColorMapping = {
  neutral: 'text-sp-neutral-600',
  info: 'text-sp-primary-600',
  success: 'text-sp-success-600',
  warning: 'text-sp-warning-600',
  error: 'text-sp-error-600',
};

const borderColorMapping = {
  neutral: 'text-sp-neutral-300 bg-sp-neutral-25',
  info: 'border-sp-primary-300 bg-sp-primary-25',
  success: 'border-sp-success-300 bg-sp-success-25',
  warning: 'border-sp-warning-300 bg-sp-warning-25',
  error: 'border-sp-error-300 bg-sp-error-25',
};

const textColorMapping = {
  neutral: 'text-sp-neutral',
  info: 'text-sp-primary',
  success: 'text-sp-success',
  warning: 'text-sp-warning',
  error: 'text-sp-error',
};

const supportingTextColorMapping = {
  neutral: 'text-sp-neutral-600',
  info: 'text-sp-primary-600',
  success: 'text-sp-success-600',
  warning: 'text-sp-warning-600',
  error: 'text-sp-error-600',
};

const VariantContext = createContext({} as any);

const Alert = ({ variant, message, children, dismissable }: AlertPropTypes) => {
  const [dismiss, setDismiss] = useState<boolean | null>(null);
  const alertContent =
    message !== null ? (
      <p className={`text-sm font-normal leading-5`}>{message}</p>
    ) : (
      children
    );

  return (
    <VariantContext.Provider value={{ variant }}>
      {!dismiss ? (
        <>
          <div
            className={cn(
              'shadow-xs flex w-full flex-row content-start gap-3 rounded-lg border px-4 py-4',
              borderColorMapping[variant],
            )}
          >
            <Icon
              size={'xl'}
              name={iconTypeMapping[variant]}
              color={iconColorMapping[variant]}
            />

            <div
              className={`w-full font-normal ${supportingTextColorMapping[variant]}`}
            >
              {alertContent}
            </div>
            {dismissable ? (
              <button onClick={() => setDismiss(true)} className="flex">
                <Icon
                  size={'md'}
                  name={'closeCross'}
                  color={iconColorMapping[variant]}
                />
              </button>
            ) : null}
          </div>
        </>
      ) : null}
    </VariantContext.Provider>
  );
};

const AlertHeader = ({ children }) => {
  const { variant } = useContext(VariantContext);

  return (
    <h4 className={`text-sm font-medium ${textColorMapping[variant]}`}>
      {children}
    </h4>
  );
};

Alert.Header = AlertHeader;

Alert.defaultProps = {
  children: null,
  message: null,
  dismissable: false,
};

export default Alert;
