import React from 'react';

import cn from 'classnames';

const mapSpinnerSize = {
  xs: 'h-4 w-4',
  sm: 'h-8 w-8',
  md: 'h-12 w-12',
  lg: 'h-14 w-14',
};

interface LoadingSpinnerProps {
  size: 'xs' | 'sm' | 'md' | 'lg';
}

const LoadingSpinner = ({ size }: LoadingSpinnerProps) => (
  <div className={cn(`inline-block`, mapSpinnerSize[size])}>
    <svg viewBox="0 0 50 50" className={`spinner animate-spin`}>
      <circle className="ring" cx="25" cy="25" r="22.5" />
      <circle className="line" cx="25" cy="25" r="22.5" />
    </svg>
  </div>
);

LoadingSpinner.defaultProps = {
  size: 'xs',
};

export default LoadingSpinner;
