import * as React from 'react';

import { useComboBox, useButton, useSearchField } from 'react-aria';
import { useComboBoxState, useSearchFieldState } from 'react-stately';

import { Icon } from '@components';
import { ListBox } from '@components/ListBox';
import { Popover } from '@components/Popover';

import type { ComboBoxProps } from '@react-types/combobox';

export { Item } from 'react-stately';

export function SearchBar<T extends object>(props: ComboBoxProps<T>) {
  const state = useComboBoxState({ ...props });

  const inputRef = React.useRef(null);
  const listBoxRef = React.useRef(null);
  const popoverRef = React.useRef(null);

  const { inputProps, listBoxProps, labelProps } = useComboBox(
    {
      ...props,
      inputRef,
      listBoxRef,
      popoverRef,
    },
    state,
  );

  // Get props for the clear button from useSearchField
  const searchProps = {
    label: props.label,
    value: state.inputValue,
    onChange: (v: string) => state.setInputValue(v),
  };

  const searchState = useSearchFieldState(searchProps);
  const { clearButtonProps } = useSearchField(searchProps, searchState, inputRef);
  const clearButtonRef = React.useRef(null);
  const { buttonProps } = useButton(clearButtonProps, clearButtonRef);
  const outerRef = React.useRef(null);

  return (
    <div className="w-98 relative inline-flex flex-col">
      <label
        {...labelProps}
        className="block text-left text-sm font-medium text-gray-700"
      >
        {props.label}
      </label>
      <div
        ref={outerRef}
        className={`relative flex inline-flex w-full flex-row items-center overflow-hidden rounded-lg border-2 px-2 shadow-sm ${
          state.isFocused ? 'border-sp-primary-300' : 'border-gray-300'
        }`}
      >
        <Icon name="search" aria-hidden="true" />
        <input
          {...inputProps}
          ref={inputRef}
          className="w-full appearance-none px-3 py-1 outline-none"
        />
        <button
          {...buttonProps}
          ref={clearButtonRef}
          style={{
            visibility: state.inputValue !== '' ? 'visible' : 'hidden',
          }}
          className="cursor-default text-gray-500 hover:text-gray-600"
        >
          <Icon aria-hidden="true" name="closeCross" />
        </button>
      </div>
      {state.isOpen && (
        <Popover
          popoverRef={popoverRef}
          triggerRef={outerRef}
          state={state}
          isNonModal
          placement="bottom start"
          className="w-98"
        >
          <ListBox {...listBoxProps} listBoxRef={listBoxRef} state={state} />
        </Popover>
      )}
    </div>
  );
}
