import React, { ReactNode } from 'react';

import cn from 'classnames';
import ReactSelect, {
  DropdownIndicatorProps,
  InputProps,
  components,
  ValueContainerProps,
} from 'react-select';

import colors from '../colors';
import Icon from '../Icon';

interface Option {
  label: string;
  value: string;
}

interface SelectProps {
  onClick?: () => {} | void;
  onClickOption: (variable: string) => void;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  options: Option[];
  children?: ReactNode;
  placeholder?: ReactNode;
}

const mapBaseSize = {
  sm: 'px-3.5 py-2 text-xs rounded-lg',
  md: 'px-4 py-2.5 text-sm rounded-lg',
  lg: 'px-4.5 py-2.5 text-base rounded-lg',
  xl: 'px-5 py-3 text-base rounded-lg',
  '2xl': 'px-7 py-4 text-lg rounded-lg',
};

const mapInputSize = {
  sm: 'calc(0.875rem - 6px)',
  md: 'calc(1rem - 6px)',
  lg: 'calc(1.125rem - 6px)',
  xl: 'calc(1.25rem - 6px)',
  '2xl': 'calc(1.75rem - 6px)',
};

const CustomValueContainer = ({
  children,
  ...props
}: ValueContainerProps<Option, false>) => {
  const { onClick, menuIsOpen, placeholder, label, size } = props.selectProps;
  const input = children?.[1];
  const { value } = input.props as InputProps;

  return (
    <div
      className="grow"
      onClick={!menuIsOpen && onClick ? onClick : undefined}
    >
      <components.ValueContainer {...props}>
        <div
          className={cn(
            'flex self-stretch  m-0 col-span-full row-start-1 row-end-2',
            mapBaseSize[size ?? 'md'],
            {
              'justify-center bg-sp-primary-600 text-sp-white': Boolean(label),
              'text-base text-sp-neutral-500 justify-start':
                !label && placeholder,
              visible: (label || placeholder) && !value,
              'z-10': (label || placeholder) && !value,
            },
          )}
        >
          {label || placeholder || '&nbsp;'}
        </div>
        {input}
      </components.ValueContainer>
    </div>
  );
};

const CustomDropdownIndicator = (
  props: DropdownIndicatorProps<Option, false>,
) => {
  const { size } = props.selectProps;
  return (
    <components.DropdownIndicator {...props}>
      <Icon size={size} color="text-sp-white" name="chevronDown" />
      <div className={cn(mapBaseSize[size ?? "md"], 'pb-0 pt-0')} />
    </components.DropdownIndicator>
  );
};

const ButtonDropdown = ({
  onClick,
  onClickOption,
  options,
  children,
  size = 'md',
  placeholder,
}: SelectProps) => {
  const withSeparator = Boolean(onClick);

  return (
    <ReactSelect
      components={{
        ValueContainer: CustomValueContainer,
        DropdownIndicator: CustomDropdownIndicator,
      }}
      controlShouldRenderValue={false}
      isOptionSelected={() => false}
      onChange={(option) => {
        if (option) {
          onClickOption(option?.value);
        }
      }}
      onClick={onClick}
      openMenuOnClick={!onClick || !children}
      options={options}
      placeholder={placeholder}
      label={children ?? ''}
      size={size}
      classNames={{
        valueContainer: () => 'h-full',
        dropdownIndicator: () =>
          cn('flex-col self-stretch items-center justify-center'),
        menu: () => 'min-w-56',
        option: ({ isFocused }) =>
          cn('sp-dropdown-menu-item', { 'bg-sp-neutral-50': isFocused }),
        input: () => mapBaseSize[size],
      }}
      styles={{
        control: (base, { isFocused }) => ({
          ...base,
          alignItems: '',
          backgroundColor: colors.primary[600],
          borderColor: colors.primary[600],
          borderRadius: '0.5rem',
          minHeight: '',
          boxShadow: isFocused ? '0 0 8px rgba(0,0,0,0.2)' : '',
          ':hover': {
            borderColor: colors.primary[600],
          },
          zIndex: 1,
        }),
        valueContainer: (base) => ({
          ...base,
          padding: '',
          paddingRight: 0,
          gridTemplateColumns: '1fr',
        }),
        input: (base) => ({
          ...base,
          backgroundColor: colors.white,
          borderRadius: '0.25rem',
          padding: 0,
          paddingLeft: mapInputSize[size],
          margin: 0,
          position: 'absolute',
          inset: 6,
          right: 0,
          overflow: 'hidden',
        }),
        dropdownIndicator: (base, { selectProps: { menuIsOpen } }) => ({
          ...base,
          height: 'auto',
          padding: '',
          transform: menuIsOpen ? 'rotateX(180deg)' : '',
          transition: 'all 500ms',
        }),
        indicatorSeparator: (base) => ({
          ...base,
          backgroundColor: colors.white,
          display: withSeparator ? 'initial' : 'none',
          margin: 0,
          width: 2,
        }),
        option: () => ({}),
      }}
      value={{ label: '', value: '' }}
    />
  );
};

export default ButtonDropdown;
