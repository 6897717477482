import React from 'react';

import cn from 'classnames';

import ButtonBase, { ButtonBaseProps } from './ButtonBase';

interface PlainButtonProps extends Omit<ButtonBaseProps, 'className'> {
  variant: 'primary' | 'danger';
}

//this mapping uses new colours - to be used once all components are completed
export const mapPlainVariant = {
  primary: 'sp-btn-plain-primary ',
  danger: 'sp-btn-plain-danger',
};

const PlainButton = ({ variant, children, ...restProps }: PlainButtonProps) => {
  return (
    <ButtonBase {...restProps} className={cn(mapPlainVariant[variant])}>
      {children}
    </ButtonBase>
  );
};

export default PlainButton;
