import * as React from 'react';

import { DismissButton, Overlay, usePopover } from '@react-aria/overlays';

import type { AriaPopoverProps } from '@react-aria/overlays';
import type { OverlayTriggerState } from 'react-stately';

interface PopoverProps extends Omit<AriaPopoverProps, 'popoverRef'> {
  children: React.ReactNode;
  state: OverlayTriggerState;
  className?: string;
  popoverRef?: React.RefObject<HTMLDivElement>;
}

function Popover(props: PopoverProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const { popoverRef = ref, state, children, className, isNonModal } = props;

  const { popoverProps } = usePopover(
    {
      ...props,
      popoverRef,
    },
    state,
  );

  return (
    <Overlay>
      {!isNonModal && <div className="fixed inset-0" />}
      <div
        {...popoverProps}
        ref={popoverRef}
        className={`border-sp-neutral-300 z-10 mt-2 rounded-lg border bg-white shadow-lg ${className}`}
      >
        {!isNonModal && <DismissButton onDismiss={state.close} />}
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
}
Popover.defaultProps = {
  className: '',
};

export default Popover;
