import React from 'react';

import IconButton from '../Button/IconButton';

const DeleteButton = ({
  handleDelete,
  handleDeleteParameters,
  disabled,
  ...props
}) => {
  return (
    <IconButton.Plain
      disabled={disabled}
      iconName="trashCan"
      variant="danger"
      onClick={() => {
        if (!disabled) {
          handleDelete(...handleDeleteParameters);
        }
      }}
      tooltipText={'Delete'}
      {...props}
    />
  );
};

DeleteButton.defaultProps = {
  disabled: false,
};

export default DeleteButton;
