import React from 'react';

import cn from 'classnames';

import { LoadingSpinner } from '../LoadingSpinner';

export interface ButtonBaseProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Show loading spinner*/
  loading?: boolean;
  /** Label text in button when loading is true */
  loadingText?: string;
  /** React node */
  children: React.ReactNode;
  className?: string;
  /** render it as 'button' or 'anchor' */
  //   as?: keyof JSX.IntrinsicElements['a' | 'button'];
  as?: 'button';
  /** Makes button disabled */
  disabled?: boolean | undefined;
  href?: string | undefined;
  target?: string | undefined;
  /** Size of the button */
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  /** Adds Icon before button label */
  leftIcon?: React.ReactElement;
  /** Adds Icon after button label */
  rightIcon?: React.ReactElement;
  iconOnly?: boolean;
  style?: React.CSSProperties;
}

const mapBaseSize = {
  sm: 'sp-btn-sm',
  md: 'sp-btn-md',
  lg: 'sp-btn-lg',
  xl: 'sp-btn-xl',
  '2xl': 'sp-btn-2xl',
};

const ButtonBase = ({
  loading,
  loadingText,
  className,
  size = 'md',
  as = 'button',
  children,
  leftIcon,
  rightIcon,
  iconOnly,
  ...restProps
}: ButtonBaseProps) => {
  const Element = as;

  return (
    <Element
      {...restProps}
      className={cn(`sp-btn flex items-center`, mapBaseSize[size], className)}
    >
      {leftIcon && !loading ? <span> {leftIcon} </span> : null}
      {!iconOnly && (
        <>
          {loading && (
            <span className="flex pr-2">
              <LoadingSpinner />{' '}
            </span>
          )}
          {loading ? (
            loadingText || <span>{children}</span>
          ) : (
            <span
              className={cn({
                'pr-2': rightIcon,
                'pl-2': leftIcon,
              })}
            >
              {children}
            </span>
          )}
        </>
      )}
      {rightIcon && <span className="">{rightIcon}</span>}
    </Element>
  );
};

ButtonBase.defaultProps = {
  loading: false,
  loadingText: null,
  className: null,
  size: 'md',
  as: 'button',
  leftIcon: null,
  rightIcon: null,
};

export default ButtonBase;
