import React, { useEffect, useRef } from 'react';

import Icon from '../Icon';

interface ToastProps {
  children: React.ReactNode;
  variant: 'warning' | 'info' | 'success' | 'error';
  remove: () => void;
}

const Toast = ({ children, variant, remove }: ToastProps) => {
  const removeRef: React.MutableRefObject<any> = useRef();
  removeRef.current = remove;

  useEffect(() => {
    const duration = 3000;
    const id = setTimeout(() => removeRef.current(), duration); // hide notification after 3 seconds

    return () => clearTimeout(id);
  }, []);

  const icon = (name, color) => (
    <Icon name={name} color={`text-${color}-600`} />
  );

  const displayIcon = () => {
    switch (variant) {
      case 'warning':
        return icon('warningTriangleExclamation', 'orange');

      case 'info':
        return icon('infoCircleLetterI', 'blue');

      case 'success':
        return icon('tick', 'green');

      case 'error':
        return icon('errorCircleExclamation', 'red');

      default:
        return null;
    }
  };

  return (
    <div className="notification show pointer-events-none fixed inset-0 z-50 flex items-start justify-end px-4 py-6">
      <div className="pointer-events-auto w-full max-w-sm rounded-lg bg-white shadow-lg">
        <div className="shadow-xs overflow-hidden rounded-lg">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">{displayIcon()}</div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium leading-5 text-gray-900">
                  {children}
                </p>
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <button
                  className="inline-flex text-gray-400 transition duration-150 ease-in-out focus:text-gray-500 focus:outline-none"
                  onClick={remove}
                >
                  <svg
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toast;
