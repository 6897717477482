import React from 'react';

const TableHeaderCell = ({ children, colspan, className }: any) => {
  return (
    <th className={`uppercase ${className}`} colSpan={colspan}>
      {children}
    </th>
  );
};

TableHeaderCell.defaultProps = {
  colspan: null,
  className: '',
};

export default TableHeaderCell;
