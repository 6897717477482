import React, { useState, useEffect, useRef } from 'react';

const useOutsideClick = () => {
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !((ref.current as HTMLElement).contains(e.target))) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return { ref, open, setOpen };
};

export default useOutsideClick;
