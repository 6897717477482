import React from 'react';

import cn from 'classnames';

interface LinkProps {
  href?: string;
  label: string;
  disabled: boolean;
  isActive: boolean | null;
  icon?: React.ReactNode;
  onClick?: () => void;
}

const checkActivePath = (path, currentLoc) => {
  if (path !== null) {
    return currentLoc.startsWith(path);
  }
};

const Link = ({
  href,
  label,
  disabled,
  isActive,
  icon,
  onClick,
}: LinkProps): JSX.Element => {
  const activeClass = `bg-sp-primary-500 text-white font-semibold `;

  let activeMenu;
  if (isActive === null) {
    activeMenu = checkActivePath(href, location.pathname) ? activeClass : null;
  } else {
    activeMenu = isActive ? activeClass : null;
  }

  return (
    <a
      href={href}
      className={cn(
        'menu-link hover:bg-sp-primary-500 my-1 block cursor-pointer px-6 py-2 text-sm leading-5 hover:font-semibold hover:text-white active:text-white',
        activeMenu,
        {
          'cursor-not-allowed opacity-75': disabled === true,
          'flex items-center': icon,
        },
      )}
      onClick={onClick}
    >
      {icon && <span className="inline-bloc mr-2">{icon}</span>}
      {label}
    </a>
  );
};

export default Link;
