import React, { useState, useCallback, Children, isValidElement } from 'react';

import { Link as TSRLink } from '@tanstack/react-router';
import cn from 'classnames';
import { Link as RRLink } from 'react-router-dom';

import { useBreakpoint } from '@hooks';

import { Dropdown } from '../Dropdown';
import { Pill } from '../Pill';

import Tab from './Tab';


export interface TabsBaseProps {
  children: React.ReactNode;
  size: 'sm' | 'md';
  vertical?: boolean;
  type?: any;
  className?: string;
  defaultTab?: string;
  linked?: 'tsr' | 'rr';
  route?: string;
}

const mapTabsSize = {
  sm: 'text-sm',
  md: 'text-base',
};

const TabElement = ({ linked, path, className, onClick, children }) => {
  if (linked === 'tsr') {
    return (
      <TSRLink search={{ tab: path }} onClick={onClick} className={className}>
        {children}
      </TSRLink>
    );
  } else if (linked === 'rr') {
    return (
      <RRLink to={`?tab=${path}`} onClick={onClick} className={className}>
        {children}
      </RRLink>
    );
  } else {
    return (
      <button onClick={onClick} className={className}>
        {children}
      </button>
    );
  }
};

const TabsBase = ({
  children,
  size,
  vertical,
  type,
  className,
  defaultTab,
  route,
  linked,
}: TabsBaseProps): JSX.Element => {
  const validChildren = Children.toArray(children).filter(
    (child, index) => isValidElement(child) && child.type === Tab,
  ) as any[];

  const breakpoint = useBreakpoint();

  const tabs = validChildren.map((child) => {
    return child.props;
  });

  const validActiveTab = tabs[0].label;

  const queryTabExist = validChildren.find(
    (child) => child.props.label === defaultTab,
  );

  const initialTab = queryTabExist ? queryTabExist.props.label : validActiveTab;

  const [activeTab, setActiveTab] = useState(initialTab);
  const handleActiveTab = useCallback((label, trackClick) => {
    setActiveTab(label);
    if (trackClick) {
      trackClick();
    }
  }, []);

  let tabPanel;

  if (breakpoint === 'sm') {
    tabPanel = (
      <Dropdown
        onChangeFunction={(value) => {
          handleActiveTab(
            value,
            tabs.find((tab) => tab.label === value).trackClick,
          );
        }}
        onChangeParameters={[]}
        value={activeTab}
      >
        {tabs.map((tab) => {
          return (
            <option key={tab.label} value={tab.label}>
              {tab.tabName}
            </option>
          );
        })}
      </Dropdown>
    );
  } else {
    tabPanel = (
      <div
        role="tablist"
        className={cn({
          'border-sp-neutral-300 mb-4 border-b':
            vertical === false && type === 'underline',
          'bg-sp-neutral-50 border-sp-neutral-100 mr-4 rounded-md p-1':
            type === 'buttonBorder',
          'mr-4': type === 'button',
        })}
      >
        {tabs.map((tab) => {
          return (
            <TabElement
              linked={linked}
              path={linked ? tab.label : null}
              onClick={() => handleActiveTab(tab.label, tab.trackClick)}
              className={cn(
                `text-sp-neutral-500 font-medium`,
                mapTabsSize[size],
                {
                  'text-sp-neutral bg-sp-neutral-50':
                    tab.label === activeTab && type === 'button',
                  'text-sp-primary border-sp-primary border-b-2':
                    vertical === false &&
                    tab.label === activeTab &&
                    type === 'underline',
                  'text-sp-neutral bg-sp-white shadow':
                    tab.label === activeTab && type === 'buttonBorder',
                  'inline-block': linked,
                },
                className,
              )}
              key={tab.label}
            >
              <span className="flex-col">
                <span
                  className={cn(`flex flex-row items-center justify-center`)}
                >
                  <span>{tab.tabName}</span>
                  {tab.badge !== null ? (
                    <span className="ml-2">
                      <Pill
                        colour={
                          tab.label === activeTab && type === 'underline'
                            ? 'purple'
                            : 'gray'
                        }
                        text={tab.badge.toString()}
                      />
                    </span>
                  ) : null}
                </span>
              </span>
            </TabElement>
          );
        })}
      </div>
    );
  }

  const tabContent = validChildren.find((child, index) => {
    return child.props.label === activeTab;
  });

  return (
    <div
      className={cn({
        'flex flex-row': vertical === true && breakpoint !== 'sm',
      })}
    >
      {tabPanel}

      <div className="w-full">{tabContent}</div>
    </div>
  );
};

TabsBase.defaultProps = {
  size: 'sm',
  vertical: false,
  type: null,
};

export default TabsBase;
