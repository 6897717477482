import React from 'react';

interface TabProps {
  label: string;
  tabName: string;
  badge?: number | undefined;
  children: React.ReactNode;
  trackClick?: () => void;
}

const Tab = ({
  label,
  tabName,
  badge,
  children,
  trackClick,
  ...otherProps
}: TabProps): JSX.Element => {
  return (
    <div aria-labelledby={`${label} content`} {...otherProps}>
      {children}
    </div>
  );
};

Tab.defaultProps = {
  badge: null,
  trackClick: null,
};

export default Tab;
