import React from 'react';

const ToggleButton = ({ checked, onClickFunction, onClickParameters }) => {
  const backgroundColor = checked
    ? 'bg-sp-primary-600 hover:bg-sp-primary'
    : 'bg-sp-neutral-100 hover:bg-sp-neutral-200';

  const sliderTranslate = checked ? 'translate-x-4' : 'translate-x-0';

  return (
    <button
      className={`${backgroundColor} relative flex flex-shrink-0 h-5 w-9 border-2 border-transparent rounded-xl cursor-pointer transition-colors ease-in-out duration-200 focus:ring focus:ring-sp-primary-100`}
      type="button"
      role="checkbox"
      onClick={(e) => {
        onClickFunction(...onClickParameters);
      }}
    >
      <span
        aria-hidden="true"
        className={`${sliderTranslate} h-4 w-4 rounded-full bg-white shadow-sm focus:outline-none transform transition ease-in-out duration-200`}
      ></span>
    </button>
  );
};

export default ToggleButton;
