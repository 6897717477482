// the purpose of this script is to automatically submit the SSO login redirect form
// (django template apps/saml2/post_binding_form.html) so that users don't have to
// submit another form after we have established who their IDP is

window.onload = function () {
  const formElement: HTMLFormElement = document.getElementById(
    'SSO_Login',
  ) as HTMLFormElement;

  if (formElement) {
    formElement.submit();
  }
};
