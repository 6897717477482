import React, { useEffect, useState } from 'react';

import { jsonParseSafe } from '@utils';

import Icon from '../Icon';
import { Pill } from '../Pill';
import { Tooltip } from '../Tooltip';

interface Props {
  id: string;
  tooltip?: string;
  maxViewCount?: number;
  hideAfter: Date;
  icon?: 'left' | 'right';
  text?: string;
}

const NewFeatureBadge = ({
  id,
  tooltip,
  maxViewCount,
  hideAfter,
  icon,
  text = 'New feature!',
}: Props) => {
  const localStorageKey = id ? `new-feature-badge--${id}` : null;

  const [seenCount, setSeenCount] = useState(
    jsonParseSafe<number>(localStorage.getItem(localStorageKey ?? '')) ?? 0,
  );

  useEffect(() => {
    setSeenCount(seenCount + 1);
  }, []);

  useEffect(() => {
    localStorage.setItem(`new-feature-badge--${id}`, JSON.stringify(seenCount));
  }, [seenCount]);

  const seenTooMuch = maxViewCount && seenCount >= maxViewCount;
  const noLongerNew = hideAfter && new Date() >= hideAfter;

  const shouldHideIfSeenTooMuch =
    id && localStorageKey && (noLongerNew || seenTooMuch);

  if (shouldHideIfSeenTooMuch) {
    return null;
  }

  const badge = (
    <div className="flex items-center gap-1">
      {icon === 'left' ? <Icon name="FiArrowLeft" /> : null}
      <Pill colour="flashy-purple" text={text} />
      {icon === 'right' ? <Icon name="FiArrowRight" /> : null}
    </div>
  );

  return tooltip ? <Tooltip message={tooltip}>{badge}</Tooltip> : badge;
};

export default NewFeatureBadge;
