import React from 'react';

interface ErrorTextProps {
  text: string;
}

const ErrorText = ({ text }: ErrorTextProps): JSX.Element => (
  <p className="mt-2 text-sm text-sp-error-500">{text}</p>
);

export default ErrorText;
