import React from 'react';

import { Link as TSLink } from '@tanstack/react-router';
import { Link as RRLink } from 'react-router-dom';

import Icon from '../Icon';

interface BreadcrumbLinkProps {
  currentCrumb: boolean;
  label: string;
  href: string | undefined;
}

const BreadcrumbLink = ({
  currentCrumb,
  label,
  href,
}: BreadcrumbLinkProps): JSX.Element => {
  return (
    <>
      {!currentCrumb ? (
        <a href={href} className={`sp-breadcrumb-link focus:outline-none `}>
          {label}
        </a>
      ) : (
        <p className="sp-breadcrumb-text">{label}</p>
      )}
      {!currentCrumb ? (
        <span className="text-sp-neutral-500 px-2">
          <Icon name="arrowForward" size="lg" />
        </span>
      ) : null}
    </>
  );
};

const BreadcrumbRouterLink = ({
  currentCrumb,
  label,
  href,
}: BreadcrumbLinkProps): JSX.Element => {
  return (
    <>
      {!currentCrumb && href !== undefined ? (
        <RRLink to={href} className="sp-breadcrumb-link focus:outline-none">
          {label}
        </RRLink>
      ) : (
        <p className="sp-breadcrumb-text">{label}</p>
      )}
      {!currentCrumb ? (
        <span className="text-sp-neutral-500 px-2">
          <Icon name="arrowForward" size="lg" />
        </span>
      ) : null}
    </>
  );
};

const BreadcrumbTSRouterLink = ({
  currentCrumb,
  label,
  href,
}: BreadcrumbLinkProps): JSX.Element => {
  return (
    <>
      {!currentCrumb && href !== undefined ? (
        <TSLink to={href} className="sp-breadcrumb-link focus:outline-none">
          {label}
        </TSLink>
      ) : (
        <p className="sp-breadcrumb-text">{label}</p>
      )}
      {!currentCrumb ? (
        <span className="text-sp-neutral-500 px-2">
          <Icon name="arrowForward" size="lg" />
        </span>
      ) : null}
    </>
  );
};

interface BreadcrumbsProps {
  children: React.ReactNode;
}

const Breadcrumbs = ({ children }: BreadcrumbsProps): JSX.Element => {
  return (
    <nav
      aria-labelledby="Breadcrumbs"
      id="breadcrumbs"
      className="sp-breadcrumbs-wrapper"
    >
      <span className="flex items-center">{children}</span>
    </nav>
  );
};

BreadcrumbLink.defaultProps = {
  currentCrumb: false,
};

BreadcrumbRouterLink.defaultProps = {
  currentCrumb: false,
};

BreadcrumbTSRouterLink.defaultProps = {
  currentCrumb: false,
};

export {
  Breadcrumbs,
  BreadcrumbLink,
  BreadcrumbRouterLink,
  BreadcrumbTSRouterLink,
};
