import React from 'react';

import { NavLink } from 'react-router-dom';

const BenchmarkMenu = () => {
  return (
    <>
      <div className="mt-12">
        <NavLink
          to="/management/benchmarking/experiments/"
          className="menu-link hover:bg-sp-primary-500 my-1 block cursor-pointer px-6 py-2 text-sm leading-5 hover:font-semibold hover:text-white active:text-white"
          activeClassName={'bg-sp-primary-500 text-white font-semibold'}
          exact
        >
          Experiments
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/management/benchmarking/experiments/add/"
          className="menu-link hover:bg-sp-primary-500 my-1 block cursor-pointer py-2 pl-10 pr-6 text-sm leading-5 hover:font-semibold hover:text-white active:text-white"
          activeClassName={'bg-sp-primary-500 text-white font-semibold'}
          exact
        >
          Add
        </NavLink>
      </div>
      <div className="mt-10">
        <NavLink
          to="/management/benchmarking/pipeline_versions/"
          className="menu-link hover:bg-sp-primary-500 my-1 block cursor-pointer px-6 py-2 text-sm leading-5 hover:font-semibold hover:text-white active:text-white"
          activeClassName={'bg-sp-primary-500 text-white font-semibold'}
          exact
        >
          Pipeline Versions
        </NavLink>
      </div>
      <div className="mt-10">
        <NavLink
          to="/management/benchmarking/scores/"
          className="menu-link hover:bg-sp-primary-500 my-1 block cursor-pointer px-6 py-2 text-sm leading-5 hover:font-semibold hover:text-white active:text-white"
          activeClassName={'bg-sp-primary-500 text-white font-semibold'}
          exact
        >
          Scores
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/management/benchmarking/scores/compare/"
          className="menu-link hover:bg-sp-primary-500 my-1 block cursor-pointer py-2 pl-10 pr-6 text-sm leading-5 hover:font-semibold hover:text-white active:text-white"
          activeClassName={'bg-sp-primary-500 text-white font-semibold'}
          exact
        >
          Compare
        </NavLink>
      </div>
    </>
  );
};

export default BenchmarkMenu;
