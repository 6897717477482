module.exports = {
  //SearchPilot logo colours
  purple: {
    25: '#EDD9FE',
    50: '#DBB3FE',
    100: '#C98DFD',
    200: '#B767FD',
    300: '#A542FC',
    400: '#931CFC',
    DEFAULT: '#7603DB',
    600: '#5E02AF',
    700: '#4F0292',
    750: '#3F0275',
    800: '#2F0158',
    850: '#1F013A',
    900: '#10001D',
  },
  pink: {
    25: '#FEDDEF',
    50: '#FCBADF',
    100: '#FB98CE',
    200: '#F976BE',
    300: '#F854AE',
    400: '#F6319E',
    DEFAULT: '#E80A84',
    600: '#BA086A',
    700: '#9B0758',
    750: '#7C0546',
    800: '#5D0435',
    850: '#3E0323',
    900: '#1F0112',
  },
  yellow: {
    25: '#FFF6DD',
    50: '#FFECBB',
    100: '#FFE399',
    200: '#FFDA78',
    300: '#FFD056',
    400: '#FFC734',
    DEFAULT: '#FFB901',
    600: '#CD9400',
    700: '#AB7C00',
    750: '#896300',
    800: '#664A00',
    850: '#443100',
    900: '#221900',
  },
  //New colours for the design system. To migrate away from the above. Only to keep main 3 SP DEFAULT brand colours (purple, pink and yellow)

  white: '#ffffff',
  black: '#000000',
  //Gray as a neutral colour and is the foundation of the colour system
  neutral: {
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#EAECF0',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    DEFAULT: '#344054',
    800: '#1D2939',
    900: '#101828',
  },
  primary: {
    25: '#FCFAFF',
    50: '#F9F5FF',
    100: '#F4EBFF',
    200: '#E9D7FE',
    300: '#D6BBFB',
    400: '#B692F6',
    500: '#9E77ED',
    600: '#7F56D9',
    DEFAULT: '#6941C6',
    800: '#53389E',
    900: '#42307D',
  },
  error: {
    25: '#FFFBFA',
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FECDCA',
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    DEFAULT: '#B42318',
    800: '#912018',
    900: '#7A271A',
  },
  warning: {
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    DEFAULT: '#B54708', //DEFAULT
    800: '#93370D',
    900: '#7A2E0E',
  },
  success: {
    25: '#F6FEF9',
    50: '#ECFDF3',
    100: '#D1FADF',
    200: '#A6F4C5',
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    DEFAULT: '#027A48', //DEFAULT
    800: '#05603A',
    900: '#054F31',
  },
  secondary: {
    25: '#F5FAFF',
    50: '#EFF8FF',
    100: '#D1E9FF',
    200: '#B2DDFF',
    300: '#84CAFF',
    400: '#53B1FD',
    DEFAULT: '#2E90FA', //DEFAULT
    600: '#1570EF',
    700: '#175CD3',
    800: '#1849A9',
    900: '#194185',
  },
  accent: {
    lightblue: '#6172F3',
    lightpink: '#EE46BC',
  },
};
