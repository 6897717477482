 
import React, { useRef } from 'react';

import { useListBox, useListBoxSection, useOption } from 'react-aria';
import { useListState } from 'react-stately';

import Icon from '../Icon';

import type { AriaListBoxOptions } from '@react-aria/listbox';
import type { Node } from '@react-types/shared';
import type { ListState } from 'react-stately';

interface ListBoxProps extends AriaListBoxOptions<unknown> {
  listBoxRef?: React.RefObject<HTMLUListElement>;
  state: ListState<unknown>;
}

interface SectionProps {
  section: Node<unknown>;
  state: ListState<unknown>;
}

interface OptionProps {
  item: Node<unknown>;
  state: ListState<unknown>;
  showCheck?: boolean;
}

function ListBox(props) {
  const ref = useRef<HTMLUListElement>(null);

  let listState;
  const { listBoxRef = ref, state } = props;
  if (!state) {
    listState = useListState(props);
  } else {
    listState = state;
  }
  const { listBoxProps } = useListBox(props, listState, listBoxRef);

  return (
    <ul
      {...listBoxProps}
      ref={listBoxRef}
      className="max-h-72 w-full overflow-auto p-2 outline-none"
    >
      {Array.from(listState.collection).map((item: any) =>
        item.type === 'section' ? (
          <ListBoxSection key={item.key} section={item} state={listState} />
        ) : (
          <Option
            key={item.key}
            item={item}
            state={listState}
            showCheck={props.showCheck}
          />
        ),
      )}
    </ul>
  );
}

function ListBoxSection({ section, state }: SectionProps) {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  return (
    <>
      <li {...itemProps} className="pt-2">
        {section.rendered && (
          <span
            {...headingProps}
            className="mx-3 text-xs font-bold uppercase text-gray-500"
          >
            {section.rendered}
          </span>
        )}
        <ul {...groupProps}>
          {Array.from(section.childNodes).map((node) => (
            <Option key={node.key} item={node} state={state} />
          ))}
        </ul>
      </li>
    </>
  );
}

function Option({ item, state, showCheck }: OptionProps) {
  const ref = useRef<HTMLLIElement>(null);
  const { optionProps, isDisabled, isSelected, isFocused } = useOption(
    {
      key: item.key,
    },
    state,
    ref,
  );

  let text = 'text-gray-700';
  let bgColour;
  if (isFocused || isSelected) {
    text = 'text-sp-primary-600';
    bgColour = 'bg-sp-neutral-50';
  } else if (isDisabled) {
    text = 'text-gray-200';
  }

  return (
    <li
      {...optionProps}
      ref={ref}
      className={`text-sp-neutral hover:bg-sp-neutral-50 focus:ring-sp-primary-100 flex cursor-pointer items-center justify-between rounded-lg focus:outline-none ${text} ${bgColour} ${
        isSelected ? 'font-bold' : ''
      }`}
    >
      {item.rendered}
      {showCheck && isSelected ? <Icon name="tick" /> : null}
    </li>
  );
}

export default ListBox;
