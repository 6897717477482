import React, { useState } from 'react';

import IconButton from '../Button/IconButton';
import { LightCard } from '../Card';

const Collapsible = ({ open, title, children }) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleOpening = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <LightCard>
        <div className="my-2 flex justify-between">
          <h6 className="text-base">{title}</h6>
          <IconButton.Ghost
            iconName={!isOpen ? 'chevronDown' : 'chevronUp'}
            onClick={handleOpening}
            tooltipText={!isOpen ? 'Expand' : 'Collapse'}
          />
        </div>
        <div>{isOpen && <div>{children}</div>}</div>
      </LightCard>
    </>
  );
};

export default Collapsible;
