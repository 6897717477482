import React from 'react';

import { HelpText } from '../HelpText';
import { Label } from '../Label';

interface CheckboxProps {
  name: string;
  value: boolean;
  onChangeFunction: any;
  onChangeParameters: any[];
  helpText: string;
  disabled: boolean;
  label: string;
  id: string;
}

const Checkbox = ({
  name,
  value,
  onChangeFunction,
  onChangeParameters,
  label,
  id,
  helpText,
  disabled,
  ...restProps
}: CheckboxProps) => {
  return (
    <fieldset>
      <div className="flex items-start">
        <div className="flex h-5 items-center">
          <input
            name={name}
            id={id}
            type="checkbox"
            checked={value}
            onChange={(e) => {
              onChangeFunction(...onChangeParameters, e.target.checked);
            }}
            className="form-checkbox transition duration-150 ease-in-out"
            disabled={disabled}
            {...restProps}
          />
        </div>
        <div className="pl-2 text-sm leading-5">
          <Label label={label} id={id} />
          <HelpText>{helpText}</HelpText>
        </div>
      </div>
    </fieldset>
  );
};

Checkbox.defaultProps = {
  value: '',
  label: '',
  helpText: '',
  disabled: false,
  name: '',
};

export default Checkbox;
