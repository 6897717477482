import React from 'react';

const WarningText = ({ children, extraClasses }) => (
  <p className="mt-2 mb-2 py-2 px-4 text-sm bg-orange-500 ${extraClasses]">
    {children}
  </p>
);

WarningText.defaultProps = {
  extraClasses: '',
};

export default WarningText;
