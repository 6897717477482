import React, { createContext, useContext } from 'react';

import { ErrorText } from '../ErrorText';

interface InputGroupProps {
  label?: string;
  children?: React.ReactNode;
  error?: string;
}

const InputGroupContext = createContext({});

const useInputGroupContext = () => {
  const context = useContext(InputGroupContext);

  if (!context) {
    throw new Error(
      'This component needs to be wrapped in InputGroup component!',
    );
  }

  return context;
};

const InputGroup = ({ label, error, children }: InputGroupProps) => {
  return (
    <InputGroupContext.Provider value={{}}>
      <div className="flex flex-col items-stretch">
        {error && <ErrorText text={error} />}
        {label && <label className="sp-input-field-label">{label}</label>}
        <fieldset className="sp-input-group">{children}</fieldset>
      </div>
    </InputGroupContext.Provider>
  );
};

const Text = ({ children }) => {
  useInputGroupContext();
  return (
    <span className="align-center border-1 border-sp-neutral-300 flex rounded rounded-lg border bg-gray-100 px-3 py-2 text-center text-sm">
      {children}
    </span>
  );
};

InputGroup.Text = Text;

export default InputGroup;
