import React from 'react';

interface TableRowProps extends React.TableHTMLAttributes<HTMLTableRowElement> {
  children: React.ReactNode;
  className?: string;
  borderTop?: string;
}

const TableRow = ({
  children,
  className,
  borderTop,
  ...restProps
}: TableRowProps) => {
  return (
    <tr className={`${borderTop} border-gray-200 ${className}`} {...restProps}>
      {children}
    </tr>
  );
};

TableRow.defaultProps = {
  borderTop: 'border-t ',
  className: '',
};

export default TableRow;
