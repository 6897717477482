import React, { useMemo } from 'react'

export const dots = '...'

export const range = (start, end) => {
    // length of pages
    const length = end - start + 1

    // create an array of length and set the elements from start to end value

    return Array.from({ length }, (v, x) => x + start)
}

export const paginate = (totalCount, limit, siblingCount, currentPage) => {
    // total pages
    const totalPageCount = Math.ceil(totalCount / limit)

    // total visible page numbers (sibling count + 2*dots + first page + current page + last page)
    const totalPageNum = siblingCount + 5


    //if total page count is less than total page numbers, only show 1 to totalPageCount
    if (totalPageNum >= totalPageCount) {
        return range(1, totalPageCount)
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount)

    const showLeftDots = leftSiblingIndex > 2;
    const showRightDots = rightSiblingIndex < totalPageCount - 2

    const firstPageIndex = 1
    const lastPageIndex = totalPageCount

    // right dots to show

    if (!showLeftDots && showRightDots) {
        const leftItemCount = 3 + 2 * siblingCount;
        const leftRange = range(1, leftItemCount);

        return [...leftRange, dots, totalPageCount];
    }

    // left dots to show
    if (showLeftDots && !showRightDots) {

        const rightItemCount = 3 + 2 * siblingCount;
        const rightRange = range(
            totalPageCount - rightItemCount + 1,
            totalPageCount
        );
        return [firstPageIndex, dots, ...rightRange];
    }

    // right and left dots to show
    if (showLeftDots && showRightDots) {
        const middleRange = range(leftSiblingIndex, rightSiblingIndex)
        return [firstPageIndex, dots, ...middleRange, dots, lastPageIndex]
    }
}

export const usePagination = ({
    totalCount, //total count of data from source
    limit, // max data visible in a single page
    siblingCount = 1, //min number of page buttons on each side of active page
    currentPage // current active page
}) => {
    const paginationRange = useMemo(() => {

        return paginate(totalCount, limit, siblingCount, currentPage)

    }, [totalCount, limit, siblingCount, currentPage]);

    return paginationRange
}