import React from 'react';

const TableHeaderRow = ({ children, extraClasses }) => {
  return (
    <tr
      className={`px-6 py-4 text-sm leading-5 bg-neutral-100 text-white align-top ${extraClasses}`}
    >
      {children}
    </tr>
  );
};

TableHeaderRow.defaultProps = {
  extraClasses: '',
};

export default TableHeaderRow;
