import { getCookie } from './utils';
const starredAccountsDiv = document.getElementById('starred-accounts');

if (starredAccountsDiv) {
  // only triggeres JS if user has access to starred accounts
  document.addEventListener('DOMContentLoaded', (event) => {
    const stars = Array.from(document.querySelectorAll('[star]'));

    const starredAccountsList = document.getElementById(
      'starred-accounts-list',
    );

    const setVisibilityStarredAccountsList = () => {
      if ((starredAccountsList?.childNodes.length ?? 0) > 1) {
        return starredAccountsDiv.setAttribute('style', 'display:block');
      }

      return starredAccountsDiv.setAttribute('style', 'display:none');
    };

    setVisibilityStarredAccountsList(); // initial check

    const postData = (starred, accountSlug) => {
      const csrftoken = getCookie('csrftoken');

      const url = `${accountSlug}/starred/${starred}`;

      return fetch(url, {
        method: 'post',
        headers: new Headers({
          'X-CSRFToken': csrftoken || '',
        }),
      });
    };
    const toggleStar = (element, status) => {
      element.classList.remove(
        status === 'on' ? 'text-gray-400' : 'text-yellow-300',
      );
      element.classList.add(
        status === 'on' ? 'text-yellow-300' : 'text-gray-400',
      );
      element.removeAttribute(status === 'on' ? 'star-off' : 'star-on');
      element.setAttribute(status === 'on' ? 'star-on' : 'star-off', '');
    };

    const handleStarClick = (element) => {
      if (element.hasAttribute('star-on')) {
        postData('off', element.getAttribute('data-account-slug'))
          .then((data) => {
            if (data.status === 200) {
              toggleStar(element, 'off');
              removeStarredElementsFromLists(element);
            }
          })
          .catch((error) => console.log(error));
      }

      if (element.hasAttribute('star-off')) {
        postData('on', element.getAttribute('data-account-slug'))
          .then((data) => {
            if (data.status === 200) {
              toggleStar(element, 'on');
              addToStarredAccountsList(element);
            }
          })
          .catch((error) => console.log(error));
      }
    };

    const removeStarredElementsFromLists = (star) => {
      // this function will remove two elements:
      // one in the starred list and the other in the regular list
      const accountSlug = star.getAttribute('data-account-slug');

      const elementToBeRemoved = document.querySelectorAll(
        `[data-account-slug='${accountSlug}']`,
      );

      elementToBeRemoved.forEach((element) => {
        // disable stars
        toggleStar(element, 'off');
      });

      Array.from(
        document.querySelectorAll(
          `#starred-accounts-list [data-account-slug='${accountSlug}']`,
        ),
      ).map((element) =>
        element?.parentElement?.parentElement?.parentElement?.remove(),
      ); // remove all disabled stars

      setVisibilityStarredAccountsList();
    };

    const addToStarredAccountsList = (star) => {
      const element = star.parentNode.parentNode.parentNode;

      const clonedElement = element.cloneNode(true); // we add a copy of the li element

      const clonedStar = clonedElement.querySelector('[star]');

      clonedStar.addEventListener('click', (event) => handleStarClick(star));

      starredAccountsList?.appendChild(clonedElement);

      setVisibilityStarredAccountsList();
    };

    stars.forEach((star) => {
      // show/hide stars on click
      star.addEventListener('click', (event) => handleStarClick(star));
    });
  });
}
