import { useEffect, useState, useCallback } from 'react';

// HTML blacklisted target tags to check that the DOM element isn't an input or a text area as we don't want to trigger keyboard shortcuts when the user is typing and hits the shortcut keys
const blacklistedTargets = ['TEXTAREA', 'INPUT'];

function useKeyPress(targetKey: string) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState<boolean>(false);

  // Add event listeners
  const keydownListener = useCallback(
    (keydownEvent) => {
      const { key, target, repeat } = keydownEvent;
      if (repeat) return;
      if (blacklistedTargets.includes(target.tagName)) return;

      if (
        (key === 'Control' && targetKey === 'Meta') ||
        (key === 'Meta' && targetKey === 'Control')
      ) {
        setKeyPressed(true);
      }
      if (!keyPressed && key === targetKey) {
        setKeyPressed(true);
      }
    },
    [targetKey],
  );

  const keyupListener = useCallback(
    (keyupEvent) => {
      const { key, target } = keyupEvent;
      if (blacklistedTargets.includes(target.tagName)) return;

      setKeyPressed(false);
    },
    [targetKey],
  );

  useEffect(() => {
    window.addEventListener('keydown', keydownListener, true);
    window.addEventListener('keyup', keyupListener, true);
    return () => {
      window.removeEventListener('keydown', keydownListener, true);
      window.removeEventListener('keyup', keyupListener, true);
    };
  }, [keydownListener, keyupListener]);

  return keyPressed;
}

const useKeyCombo = (keyCombo: string[]) => {
  const keyPresses = keyCombo.map((key) => useKeyPress(key));
  return keyPresses.every((keyPressed) => keyPressed === true);
};

export const useOnKeyPress = (keyCombo: string[], callback) => {
  if (!Array.isArray(keyCombo)) {
    throw new Error(
      'The first parameter to `useOnKeyPress` must be an array of `KeyboardEvent.key` strings.',
    );
  }

  if (!keyCombo.length) {
    throw new Error(
      'The first parameter to `useOnKeyPress` must contain at least one `KeyboardEvent.key` string.',
    );
  }

  if (!keyCombo || typeof callback !== 'function') {
    throw new Error(
      'The second parameter to `useOnKeyPress` must be a function that will be called when the keys are pressed.',
    );
  }
  const isKeyComboPressed = useKeyCombo(keyCombo);
  useEffect(() => {
    if (isKeyComboPressed) {
      callback();
    }
  }, [isKeyComboPressed]);
};
