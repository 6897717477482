import React, { useState } from 'react';

import { Label } from '../Label';

interface DateSelectorType {
  initialDate: any;
  updateDate: any;
  label: string;
  hideLabel: boolean;
  id?: string;
}

const DateSelector = ({
  initialDate,
  updateDate,
  label,
  hideLabel,
  id,
}: DateSelectorType) => {
  const [selectedDate, setSelectedDate] = useState('');

  const handleChange = (e) => {
    e.preventDefault;
    setSelectedDate(`${e.target.value}`);
    updateDate(`${e.target.value}`);
  };

  return (
    <div>
      {hideLabel ? null : <Label label={label} id={id ?? ''} />}{' '}
      <div className="sp-input-field-div-wrapper flex">
        <input
          className="sp-input sp-input sp-input-field sp-select-field"
          value={selectedDate}
          type="date"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

DateSelector.defaultProps = {
  hideLabel: false,
  label: null,
};

export default DateSelector;
