import * as React from 'react';

import { useComboBox, useFilter, useButton } from 'react-aria';
import { useComboBoxState } from 'react-stately';

import Icon from '../Icon';
import { ListBox } from '../ListBox';
import { Popover } from '../Popover';

import type { ComboBoxProps } from '@react-types/combobox';

export { Item, Section } from 'react-stately';

interface ComboBoxPropsWithClassName<T> extends ComboBoxProps<T> {
  showBorder?: boolean;
}

const ComboBox = <T extends object>(props: ComboBoxPropsWithClassName<T>) => {
  const { contains } = useFilter({ sensitivity: 'base' });
  const state = useComboBoxState({ ...props, defaultFilter: contains });

  const buttonRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const listBoxRef = React.useRef(null);
  const popoverRef = React.useRef(null);

  const {
    buttonProps: triggerProps,
    inputProps,
    listBoxProps,
    labelProps,
  } = useComboBox(
    {
      ...props,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef,
    },
    state,
  );

  const { buttonProps } = useButton(triggerProps, buttonRef);

  return (
    <div className="relative inline-flex w-60 flex-col">
      <label
        {...labelProps}
        className="block text-left text-sm font-medium text-gray-700"
      >
        {props.label}
      </label>
      <div
        className={`relative flex inline-flex flex-row overflow-hidden rounded-lg py-2  ${
          props.showBorder
            ? 'border-sp-neutral-300 border border-solid shadow-sm'
            : 'border border-transparent focus:border-solid'
        } ${
          state.isFocused
            ? 'ring-sp-primary-100 border-sp-primary-300 border border-solid ring'
            : 'hover:border-sp-neutral-100 hover:ring-sp-neutral-50 hover:border-solid hover:ring'
        } `}
      >
        <input
          {...inputProps}
          ref={inputRef}
          className="w-full pl-3.5 outline-none"
        />

        <button
          {...buttonProps}
          ref={buttonRef}
          className={`cursor-default pl-1 pr-3.5 ${
            state.isFocused
              ? 'border-primary-500 text-sp-primary'
              : 'border-sp-neutral-300 text-sp-neutral-500'
          }`}
        >
          {state.isFocused && state.isOpen ? (
            <Icon aria-hidden="true" name="chevronUp" />
          ) : (
            <Icon aria-hidden="true" name="chevronDown" />
          )}
        </button>
      </div>
      {state.isOpen && (
        <Popover
          popoverRef={popoverRef}
          triggerRef={inputRef}
          state={state}
          isNonModal
          placement="bottom start"
          className="w-60"
        >
          <ListBox
            {...listBoxProps}
            listBoxRef={listBoxRef}
            state={state}
            showCheck={true}
          />
        </Popover>
      )}
    </div>
  );
};

export default ComboBox;
