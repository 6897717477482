import React from 'react';

const Label = ({ label, id }: { label: string; id: string }) => {
  const textColor = 'text-sp-neutral';
  const labelStyle = `block text-sm font-medium leading-5 w-full ${textColor}`;

  return (
    <label htmlFor={id} className={labelStyle}>
      {label}
    </label>
  );
};

export default Label;
