import React from 'react';

const HeadFootBlock = ({ classes, children }) => {
  return (
    <div className={`sp-head-foot-block-container ${classes}`}>{children}</div>
  );
};

const HeadFootBlockHeader = ({ title, classes, children }) => {
  return (
    <div className={`sp-head-foot-block-header ${classes}`}>
      <h2 className={`text-3xl`}>{title}</h2>
      {children}
    </div>
  );
};

const HeadFootBlockContent = ({ classes, children }) => {
  return (
    <div className={`sp-head-foot-block-content ${classes}`}>{children}</div>
  );
};

const HeadFootBlockContentItem = ({ classes, children }) => {
  return (
    <div className={`sp-head-foot-block-content-item ${classes}`}>
      {children}
    </div>
  );
};

const HeadFootBlockFooter = ({ classes, textAlignment, children }) => {
  let textAlign;
  switch (textAlignment) {
    case 'left':
      textAlign = 'text-left';
      break;
    case 'right':
      textAlign = 'text-right';
      break;
    case 'center':
      textAlign = 'text-center';
      break;
    case 'justify':
      textAlign = 'text-justify';
      break;
  }
  return (
    <div className={`sp-head-foot-block-footer ${textAlign} ${classes}`}>
      {children}
    </div>
  );
};

HeadFootBlock.defaultProps = {
  classes: '',
};

HeadFootBlockHeader.defaultProps = {
  title: '',
  children: [],
  classes: '',
};

HeadFootBlockContent.defaultProps = {
  classes: '',
};

HeadFootBlockContentItem.defaultProps = {
  classes: '',
};

HeadFootBlockFooter.defaultProps = {
  classes: '',
  textAlignment: 'right',
};

export {
  HeadFootBlock,
  HeadFootBlockHeader,
  HeadFootBlockContent,
  HeadFootBlockContentItem,
  HeadFootBlockFooter,
};
