import React from 'react';

const TableCell = ({
  children,
  colspan,
  extraClasses,
  textColor,
  backgroundColor,
  ...extraProps
}: any) => {
  return (
    <td
      className={`px-6 py-4 align-middle text-sm leading-5 ${textColor} ${backgroundColor} ${extraClasses}`}
      colSpan={colspan}
      {...extraProps}
    >
      {children}
    </td>
  );
};

TableCell.defaultProps = {
  colspan: null,
  textColor: 'text-gray-800',
  backgroundColor: 'bg-blue',
  extraClasses: '',
};

export default TableCell;
