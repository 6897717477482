import React from 'react';

import cn from 'classnames';

const Table = ({
  children,
  overflow,
  tableLayout,
  ...restProps
}: {
  children: React.ReactNode;
  overflow?: boolean;
  tableLayout: 'fixed' | 'auto';
}) => {
  return (
    //Wrapping table within div so we can scroll table if content overflows
    <div
      className={`relative align-middle inline-block w-full ${
        overflow ? 'overflow-x-auto' : 'overflow-visible'
      }`}
    >
      <table
        className={cn(` w-full`, {
          'table-auto': tableLayout === 'auto',
          'table-fixed': tableLayout === 'fixed',
        })}
        {...restProps}
      >
        {children}
      </table>
    </div>
  );
};

Table.defaultProps = {
  overflow: true,
  tableLayout: 'auto',
};

export default Table;
