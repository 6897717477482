import { useEffect, useState } from "react";

const useBreakpoint = (): string => {
    const [breakpoint, setBreakpoint] = useState(1200);
    const resize = () => setBreakpoint(window.innerWidth);
  
    useEffect(() => {
      window.addEventListener('resize', resize);
  
      return () => {
        window.removeEventListener('resize', resize);
      };
    }, []);
  
    if (breakpoint < 640) {
      return 'sm';
    } else if (breakpoint <= 768) {
      return 'md';
    } else if (breakpoint <= 1024) {
      return 'lg';
    } else if (breakpoint <= 1280) {
      return 'xl';
    } else {
      return "xl";
    }
};

export default useBreakpoint