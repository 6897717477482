import React from 'react';

import cn from 'classnames';

import { trim } from '@utils';

import { ErrorText } from '../ErrorText';
import { HelpText } from '../HelpText';
import Icon from '../Icon';
import { Label } from '../Label';

import { InputFieldProps } from './types';


const InputField = ({
  value,
  onChangeFunction,
  onChangeParameters,
  label,
  id,
  required,
  type,
  helpText,
  errorText,
  placeholder,
  trailingIcon,
  leadingIcon,
  disabled,
  onBlurFunction,
  hideLabel,
  name,
  trimMode,
  ...restProps
}: InputFieldProps) => {
  const displayType =
    trailingIcon || leadingIcon || errorText ? 'relative' : 'flex';
  const inputContainerClass = `sp-input-field-div-wrapper ${displayType} `;

  let suffixIcon: React.ReactNode | null = null;
  if (errorText) {
    suffixIcon = <Icon name="alertCircle" color="text-sp-error-500" />;
  } else {
    suffixIcon = trailingIcon;
  }

  return (
    <>
      {hideLabel ? null : <Label label={label ?? ''} id={id ?? ''} />}
      <div className={inputContainerClass}>
        {leadingIcon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {leadingIcon}
          </div>
        )}
        <input
          name={name}
          value={value}
          onChange={(e) => {
            const value = e.target.value;
            onChangeFunction(...(onChangeParameters || []), value);
          }}
          onBlur={(e) => {
            const value = e.target.value;
            const trimmedValue = trim(value, trimMode ?? 'none');
            if (value !== trimmedValue) {
              onChangeFunction(...(onChangeParameters || []), trimmedValue);
            }
            if (typeof onBlurFunction === 'function') {
              onBlurFunction();
            }
          }}
          type={type}
          id={id}
          style={{
            paddingLeft: leadingIcon ? '40px' : undefined,
            paddingRight: trailingIcon ? '40px' : undefined,
          }}
          className={cn('sp-input-field', {
            'sp-input-field-with-error': errorText,
          })}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          {...restProps}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 mr-3.5 flex items-center">
          {suffixIcon}
        </div>
      </div>
      {errorText ? <ErrorText text={errorText} /> : null}
      {helpText ? <HelpText>{helpText}</HelpText> : null}
    </>
  );
};

InputField.defaultProps = {
  id: undefined,
  value: '',
  label: '',
  required: false,
  type: 'text',
  helpText: undefined,
  errorText: undefined,
  placeholder: '',
  trailingIcon: undefined,
  leadingIcon: undefined,
  disabled: false,
  onBlurFunction: undefined,
  hideLabel: false,
  name: '',
  trimMode: 'none',
  pattern: undefined,
  min: undefined,
  max: undefined,
};

export default InputField;
