import { postJSON } from './utils';

const markDismissedURL = '/persistent_messages/mark_dismissed/';
// find all the messages
const dismissEls = document.querySelectorAll('.persistent-message-close');
dismissEls.forEach((dismissEl) => {
  // add a listening to every click even on the close button of a persistent message
  // which will send a request to the server to make as dismissed
  dismissEl.addEventListener('click', (e) => {
    const clickedElement = e.target as any;
    // we might have clicked the child svg / path

    const closeButton = clickedElement.closest('.persistent-message-close');

    const messageID = closeButton.getAttribute('data-message-id');

    // now hide the persistentMessage
    const messageDiv = closeButton.closest('[data-persistent-message]');
    (messageDiv as any).classList.add('hidden');

    // make the request
    postJSON(markDismissedURL, { message_id: messageID });
  });
});
